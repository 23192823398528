body {
  margin: 0;
  color: #9da6a6;
  background: linear-gradient(#303035, #111116 40%);
  font-family: Calibre, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: 'Calibre';
  src: 
    url('./fonts/CalibreRegular.otf') format('otf'),
    url('./fonts/CalibreRegular.otf') format('opentype');
  font-display: swap;
}

p, h1, h2, h3, h4, h5 {
  font-family: Calibre !important;
}
h2 {
  color: #f2f1fa;
}
#root {
  /* background: #0B0B0F; */
  background: transparent;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#navButton-right {
  transform: translate(25px, 0);
} 
#navButton-left {
  transform: translate(-75px, 0);
} 
.navButtonTestimonials > svg {
  width: 50px;
  height: 50px;
}

.underline-animation {
  position: relative;
}

.underline-animation::before {
  content: "";
  position: absolute;
  display: block;
  width: 100%;
  height: 1px;
  bottom: 0;
  left: 0;
  background-color: #f2f1fa;
  transform: scaleX(0);
  transition: transform 0.3s ease;
}

.underline-animation:hover::before {
  transform: scaleX(1);
}

@keyframes ani {
  0% {
    background-position: 0%;
  }

  100% {
    background-position: 400%;
  }
}
.wv-hero-video iframe {
  aspect-ratio: 16/5;
}
@media (max-width: 599.95px){
  .wv-hero-video {
    height: 100% !important;
    width: auto !important;
    left: 0;
  }
}

/* width */
::-webkit-scrollbar {
  	width: 10px;
  	height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  	background: linear-gradient(#303035, #111116 40%);; 
  	box-shadow: inset 0 0 5px grey; 
  	border-radius: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  	background: #0B0B0F; 
  	height: 5px;
  	border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  	background: #555; 
}

.MuiTimelineItem-root:nth-of-type(even) p {
  margin-right: 0;
}
.MuiTimelineItem-root:nth-of-type(odd) p {
  margin-left: 0;
}